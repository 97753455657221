<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.faculty.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.faculty.list.title"></app-i18n>
      </h1>

      <app-faculty-list-toolbar></app-faculty-list-toolbar>
      <app-faculty-list-filter></app-faculty-list-filter>
      <app-faculty-list-table></app-faculty-list-table>
    </div>
  </div>
</template>

<script>
import FacultyListFilter from '@/modules/faculty/components/faculty-list-filter.vue';
import FacultyListTable from '@/modules/faculty/components/faculty-list-table.vue';
import FacultyListToolbar from '@/modules/faculty/components/faculty-list-toolbar.vue';

export default {
  name: 'app-faculty-list-page',

  components: {
    [FacultyListFilter.name]: FacultyListFilter,
    [FacultyListTable.name]: FacultyListTable,
    [FacultyListToolbar.name]: FacultyListToolbar,
  },
};
</script>

<style>
</style>
